import type { Ref } from 'vue'

export function useCountryName(countryCode: Ref<string> | string) {
  const { t } = useI18n()

  const map: Record<string, string> = {
    aus: t('countries.aus'),
    aut: t('countries.aut'),
    aze: t('countries.aze'),
    alb: t('countries.alb'),
    dza: t('countries.dza'),
    vir: t('countries.vir'),
    asm: t('countries.asm'),
    aia: t('countries.aia'),
    ago: t('countries.ago'),
    and: t('countries.and'),
    ata: t('countries.ata'),
    atg: t('countries.atg'),
    arg: t('countries.arg'),
    arm: t('countries.arm'),
    abw: t('countries.abw'),
    afg: t('countries.afg'),
    bhs: t('countries.bhs'),
    bgd: t('countries.bgd'),
    brb: t('countries.brb'),
    bhr: t('countries.bhr'),
    blz: t('countries.blz'),
    blr: t('countries.blr'),
    bel: t('countries.bel'),
    ben: t('countries.ben'),
    bmu: t('countries.bmu'),
    bgr: t('countries.bgr'),
    bol: t('countries.bol'),
    bes: t('countries.bes'),
    bih: t('countries.bih'),
    bwa: t('countries.bwa'),
    bra: t('countries.bra'),
    iot: t('countries.iot'),
    vgb: t('countries.vgb'),
    brn: t('countries.brn'),
    bvt: t('countries.bvt'),
    bfa: t('countries.bfa'),
    bdi: t('countries.bdi'),
    btn: t('countries.btn'),
    vut: t('countries.vut'),
    vat: t('countries.vat'),
    gbr: t('countries.gbr'),
    hun: t('countries.hun'),
    ven: t('countries.ven'),
    tls: t('countries.tls'),
    vnm: t('countries.vnm'),
    gab: t('countries.gab'),
    hti: t('countries.hti'),
    guy: t('countries.guy'),
    gmb: t('countries.gmb'),
    gha: t('countries.gha'),
    glp: t('countries.glp'),
    gtm: t('countries.gtm'),
    gin: t('countries.gin'),
    gnb: t('countries.gnb'),
    deu: t('countries.deu'),
    ggy: t('countries.ggy'),
    gib: t('countries.gib'),
    hnd: t('countries.hnd'),
    hkg: t('countries.hkg'),
    grd: t('countries.grd'),
    grl: t('countries.grl'),
    grc: t('countries.grc'),
    geo: t('countries.geo'),
    gum: t('countries.gum'),
    dnk: t('countries.dnk'),
    cod: t('countries.cod'),
    jey: t('countries.jey'),
    dji: t('countries.dji'),
    dma: t('countries.dma'),
    dom: t('countries.dom'),
    egy: t('countries.egy'),
    zmb: t('countries.zmb'),
    esh: t('countries.esh'),
    zwe: t('countries.zwe'),
    isr: t('countries.isr'),
    ind: t('countries.ind'),
    idn: t('countries.idn'),
    jor: t('countries.jor'),
    irq: t('countries.irq'),
    irn: t('countries.irn'),
    irl: t('countries.irl'),
    isl: t('countries.isl'),
    esp: t('countries.esp'),
    ita: t('countries.ita'),
    yem: t('countries.yem'),
    cpv: t('countries.cpv'),
    kaz: t('countries.kaz'),
    cym: t('countries.cym'),
    khm: t('countries.khm'),
    cmr: t('countries.cmr'),
    can: t('countries.can'),
    qat: t('countries.qat'),
    ken: t('countries.ken'),
    cyp: t('countries.cyp'),
    kgz: t('countries.kgz'),
    kir: t('countries.kir'),
    chn: t('countries.chn'),
    prk: t('countries.prk'),
    cck: t('countries.cck'),
    col: t('countries.col'),
    com: t('countries.com'),
    kor: t('countries.kor'),
    kos: t('countries.kos'),
    cri: t('countries.cri'),
    civ: t('countries.civ'),
    cub: t('countries.cub'),
    kwt: t('countries.kwt'),
    cuw: t('countries.cuw'),
    lao: t('countries.lao'),
    lva: t('countries.lva'),
    lso: t('countries.lso'),
    lbr: t('countries.lbr'),
    lbn: t('countries.lbn'),
    lby: t('countries.lby'),
    ltu: t('countries.ltu'),
    lie: t('countries.lie'),
    lux: t('countries.lux'),
    mus: t('countries.mus'),
    mrt: t('countries.mrt'),
    mdg: t('countries.mdg'),
    myt: t('countries.myt'),
    mac: t('countries.mac'),
    mkd: t('countries.mkd'),
    mwi: t('countries.mwi'),
    mys: t('countries.mys'),
    mli: t('countries.mli'),
    mdv: t('countries.mdv'),
    mlt: t('countries.mlt'),
    mar: t('countries.mar'),
    mtq: t('countries.mtq'),
    mhl: t('countries.mhl'),
    mex: t('countries.mex'),
    moz: t('countries.moz'),
    mda: t('countries.mda'),
    mco: t('countries.mco'),
    mng: t('countries.mng'),
    msr: t('countries.msr'),
    mmr: t('countries.mmr'),
    nam: t('countries.nam'),
    nru: t('countries.nru'),
    npl: t('countries.npl'),
    ner: t('countries.ner'),
    nga: t('countries.nga'),
    ant: t('countries.ant'),
    nld: t('countries.nld'),
    nic: t('countries.nic'),
    niu: t('countries.niu'),
    nzl: t('countries.nzl'),
    ncl: t('countries.ncl'),
    nor: t('countries.nor'),
    are: t('countries.are'),
    omn: t('countries.omn'),
    nfk: t('countries.nfk'),
    cxr: t('countries.cxr'),
    shn: t('countries.shn'),
    hmd: t('countries.hmd'),
    cok: t('countries.cok'),
    pcn: t('countries.pcn'),
    tca: t('countries.tca'),
    wlf: t('countries.wlf'),
    pak: t('countries.pak'),
    plw: t('countries.plw'),
    pse: t('countries.pse'),
    pan: t('countries.pan'),
    png: t('countries.png'),
    pry: t('countries.pry'),
    per: t('countries.per'),
    pol: t('countries.pol'),
    prt: t('countries.prt'),
    pri: t('countries.pri'),
    cog: t('countries.cog'),
    reu: t('countries.reu'),
    rus: t('countries.rus'),
    rwa: t('countries.rwa'),
    rou: t('countries.rou'),
    slv: t('countries.slv'),
    wsm: t('countries.wsm'),
    smr: t('countries.smr'),
    stp: t('countries.stp'),
    sau: t('countries.sau'),
    swz: t('countries.swz'),
    mnp: t('countries.mnp'),
    syc: t('countries.syc'),
    spm: t('countries.spm'),
    sen: t('countries.sen'),
    vct: t('countries.vct'),
    kna: t('countries.kna'),
    lca: t('countries.lca'),
    srb: t('countries.srb'),
    sgp: t('countries.sgp'),
    syr: t('countries.syr'),
    svk: t('countries.svk'),
    svn: t('countries.svn'),
    slb: t('countries.slb'),
    som: t('countries.som'),
    sdn: t('countries.sdn'),
    sur: t('countries.sur'),
    usa: t('countries.usa'),
    sle: t('countries.sle'),
    tjk: t('countries.tjk'),
    tha: t('countries.tha'),
    twn: t('countries.twn'),
    tza: t('countries.tza'),
    tgo: t('countries.tgo'),
    tkl: t('countries.tkl'),
    ton: t('countries.ton'),
    tto: t('countries.tto'),
    tuv: t('countries.tuv'),
    tun: t('countries.tun'),
    tkm: t('countries.tkm'),
    tur: t('countries.tur'),
    uga: t('countries.uga'),
    uzb: t('countries.uzb'),
    ukr: t('countries.ukr'),
    ury: t('countries.ury'),
    fro: t('countries.fro'),
    fsm: t('countries.fsm'),
    fji: t('countries.fji'),
    phl: t('countries.phl'),
    fin: t('countries.fin'),
    flk: t('countries.flk'),
    fra: t('countries.fra'),
    guf: t('countries.guf'),
    pyf: t('countries.pyf'),
    atf: t('countries.atf'),
    hrv: t('countries.hrv'),
    caf: t('countries.caf'),
    tcd: t('countries.tcd'),
    mne: t('countries.mne'),
    cze: t('countries.cze'),
    chl: t('countries.chl'),
    che: t('countries.che'),
    swe: t('countries.swe'),
    sjm: t('countries.sjm'),
    lka: t('countries.lka'),
    ecu: t('countries.ecu'),
    gnq: t('countries.gnq'),
    eri: t('countries.eri'),
    est: t('countries.est'),
    eth: t('countries.eth'),
    zaf: t('countries.zaf'),
    sgs: t('countries.sgs'),
    jam: t('countries.jam'),
    jpn: t('countries.jpn'),
    eng: t('countries.eng'),
    wal: t('countries.wal'),
    sco: t('countries.sco'),
    ssd: t('countries.ssd'),
  }

  return computed(() => map[unref(countryCode)] ?? unref(countryCode))
}
